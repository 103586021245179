import axiosInstances from '@/libs/axios-instances'
import handleAlerts from './handleAlerts'

export default function entityStatus() {
  const { successfulOperationAlert, showErrors } = handleAlerts()
  const activateEntity = (entityId, entity) => {
    axiosInstances.entities.post('internalops/entity-activate', {
      entity_id: entityId,
    }).then(() => {
      entity.status = !entity.status
      successfulOperationAlert('Entity is activated successfully')
    }).catch(err => {
      showErrors(err.response.data.errors)
    })
  }

  const deactivateEntity = (entityId, entity) => {
    axiosInstances.entities.post('internalops/entity-deactivate', {
      entity_id: entityId,
    }).then(() => {
      entity.status = !entity.status
      successfulOperationAlert('Entity is deactivated successfully')
    }).catch(err => {
      showErrors(err.response.data.errors)
    })
  }

  return {
    activateEntity,
    deactivateEntity,
  }
}
